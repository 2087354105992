<template>
  <div class="editBackground">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">更换背景</div>
      <div class="rightBtn" @click="modifyBg">保存</div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="backgroundList">
          <div
            class="bgItem"
            v-for="item in backgroundList"
            :key="item.id"
            @click="changeActive(item)"
          >
            <ImgDecypt class="bg" :src="item.resource" :key="item.resource" />
            <svg-icon
              class="selectActive"
              iconClass="selectActive"
              v-if="selectBg == item.resource"
            />
            <div class="selectBorder" v-else></div>
          </div>
        </div>
      </PullRefresh>
    </div>

    <!-- <div class="confirmBtn" @click="modifyBg">确定</div> -->
  </div>
</template>

<script>
import { queryAvatarList } from "@/api/user";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import { modifyUserInfo } from "@/api/user";
import { Toast } from "vant";
export default {
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      backgroundList: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      selectBg: "",
    };
  },
  created() {
    this.selectBg = this.$store.getters.userInfo.background
      ? this.$store.getters.userInfo.background[0]
      : "";
    this.queryList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询头像列表
    async queryList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: "bg",
      };
      try {
        let res = await this.$Api(queryAvatarList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // this.backgroundList = res.data.list;
          if (type === "refresh") {
            this.backgroundList = list;
          } else {
            this.backgroundList = this.backgroundList.concat(list);
          }
          if (this.pageNumber == 1 && this.backgroundList.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.queryList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.queryList("refresh");
    },
    // 修改头像
    changeActive(item) {
      this.selectBg = item.resource;
    },
    // 修改头像
    async modifyBg() {
      let req = {
        background: [this.selectBg],
      };
      let ret = await this.$Api(modifyUserInfo, req);
      if (ret && ret.code == 200) {
        Toast("修改成功");
        this.$router.go(-1);
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editBackground {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    // border-bottom: 1px solid #2b274a;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 7px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
    }

    .rightBtn {
      font-size: 12px;
      color: #333;
      // width: 17px;
      // height: 17px;
    }
  }

  .main {
    height: calc(100vh - 225px);
  }

  // /deep/ .refreshing_warp {
  //     height: calc(100% - 170px);
  // }
  /deep/ .van-pull-refresh {
    height: 100%;
  }

  /deep/ .van-list__finished-text {
    display: none;
  }

  .backgroundList {
    padding: 12px 16px 126px;
    box-sizing: border-box;

    .bgItem {
      width: 100%;
      height: 138px;
      margin-bottom: 12px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      .bg {
        width: 100%;
        height: 100%;
      }

      .selectActive {
        width: 22px;
        height: 22px;
        position: absolute;
        top: 12px;
        right: 12px;
      }
      .selectBorder {
        width: 18px;
        height: 18px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        right: 12px;
      }
    }
  }

  .confirmBtn {
    width: 284px;
    height: 44px;
    border-radius: 48px;
    line-height: 44px;
    font-size: 18px;
    // margin: 30px auto;
    text-align: center;
    background: #94d6da;
    color: #fff;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
